import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";

const PrivateRoute = () => {
  const user = useAuth();
    /**
   * se o user for null envia para o inicio
   */
  if (user.user===null) return <Navigate to="/" />;
  return <Outlet />;
};

export default PrivateRoute;