import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: relative;
`

export const Spin = styled.div`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 6px solid rgba(0, 0, 0, 0.1);
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;
`

export const ImageS = styled.img`
 position: absolute;
`

export function Loading() {
  return (
    <Wrapper>
      <ImageS src="/FAVicon.png"/>
      <Spin/>
    </Wrapper>
  )
}
