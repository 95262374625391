import { useEffect, useRef, useState, lazy } from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

const Login = lazy(() => import("./Login.jsx"));
const Corrida = lazy(() => import("./Corrida"));
const CorridaFinal = lazy(() => import("./CorridaFinal"));
const Ranking = lazy(() => import("./Ranking"));
const Register = lazy(() => import("./Register"));
const RecoverPassword = lazy(() => import("./RecoverPassword"));
const ResetPassword = lazy(() => import("./ResetPassword"));
const Menu = lazy(() => import("./Menu"));
const AreaCliente = lazy(() => import("./AreaCliente"));

import AuthProvider from "./hooks/AuthProvider";
import PrivateRoute from "./router/PrivateRoute";
import Lottie from "lottie-react";
import rotate from "../public/images/roatate.json";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100vw;
  @media (min-width: 500px) {
    display: flex;
    justify-content: center;
    height: 100vh;
  }
`;

const SecondWrapper = styled.div`
  @media (min-width: 500px) {
    max-width: 500px;
  }
`;


const WrapperSlot = styled.div`
  height: 100vh;
  display: none;
  width: 100vw;
  background-color: black;
  text-align: center;
  opacity: 95%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 300;
  overflow: hidden;
`;

function App() {
  const windowHeight = useRef(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const divrotate = useRef(null);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    );
  }, []);

  document.addEventListener(
    "touchstart",
    function (event) {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    },
    { passive: false }  
  );

  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });

    useEffect(() => {
      const onTouchMove = (event) => {
        event.preventDefault()
      };

      window.addEventListener('touchmove', onTouchMove, { passive: false });

      return () => {
      window.removeEventListener('touchmove', onTouchMove);
      };
    }, []);



  useEffect(() => {
    const handleOrientationChange = () => {
      if (!divrotate.current) return;
      // Verifica a orientação atual da tela
      const orientation = window.screen.orientation.angle;
      if (orientation === 0) {
        divrotate.current.style.display = "none";
      } else {
        divrotate.current.style.display = "block";
      }
    };

    const recize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", recize);
    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
      window.removeEventListener("resize", recize);
    };
  }, [windowWidth]);

  /**
   * verifica se a resolução do ecra do jogador é adequado
   */
  useEffect(() => {
    const orientation = window.screen.orientation.angle;
    if (windowHeight.current < 480 && orientation !== 0) {
      divrotate.current.style.display = "block";
    }
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AuthProvider />}>
        <Route path="/" element={<Menu />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/recover" element={<RecoverPassword />} />
        <Route path="/recover-password/:token" element={<ResetPassword />} />
       
        <Route element={<PrivateRoute />}>
        <Route path={`dashboard`} element={<AreaCliente />} />
          <Route path={`running`} element={<Corrida />} />
          <Route path={`runningFinal`} element={<CorridaFinal />} />
          <Route path={`ranking`} element={<Ranking />} />
        </Route>
      </Route>
    )
  );

  /**
   * rotas da aplicação
   * Contem o privateRoute para garantir que a pessoa esteja registada inicialmente para poder jogar
   */
  return (
    <div className="App">

        <Wrapper>
          <SecondWrapper>
            <WrapperSlot ref={divrotate}>
              <Lottie
                animationData={rotate}
                loop={true}
                style={{ height: "100%", width: "100%" }}
              />
            </WrapperSlot>

            <RouterProvider router={router} />
          </SecondWrapper>
        </Wrapper>
      
   
    </div>
  );
}

export default App;
