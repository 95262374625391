import { useContext, createContext, useState, Suspense } from "react";
import { useNavigate, useOutlet } from "react-router-dom";
import { Loading } from "../components/Loading";

const AuthContext = createContext();

const Auth = ({ children }) => {

  const [id, setId] = useState('');
  const [user, setUser] = useState(null);
  const [run, setRun] = useState(null);
  const [name, setName] = useState(null);
  const [total, setTotal] = useState(null);
  const [ranking, setRanking] = useState(null);
  const navigate = useNavigate();

  /**
   * login
   * Guarda os dados do user para este poder fazer a participação de forma adequada
   * @param {*} user -> email do user
   * @param {*} id -> id do user
   * @returns navegação para area de escolha do jogo
   */
  const RegisterAction = (email, name) => {
    if(email==undefined) return;
    setUser(email);
    setRanking(null)
    setName(name);
    setTotal(0)
    navigate('/dashboard');
  }

  const loginAction = (email,)=>{
    setUser(email);
    navigate('/dashboard');
  }

  

  /**
   *  Guarda o resultado da corrida dentro da auth
   * Envia para a página de resultados da corrida
   * @param {*} data resultado do jogo corrida
   */
  const participaCorrida=(data)=>{
    setRun(data)
    navigate("/runningFinal");
}

/**
 *  reseta o user e todos os dados
 *  Envia para a página inicial
 */
  const logOut = () => {
    setUser(null);
    setId("");
    setRanking(null)
    setRun(null)
    setName(null);
    setTotal(null)
    navigate("/");
  };


  return (
    <AuthContext.Provider value={{ run, id, user,name, total,ranking, RegisterAction, logOut,  participaCorrida, loginAction }}>
      {children}
    </AuthContext.Provider>
  );
};

const AuthProvider = () => {
    const outlet = useOutlet()
    return <Suspense fallback={<Loading/>}><Auth>{outlet}</Auth></Suspense>
}

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
